<template>
  <div class="avatars" :class="{ small }">
    <Avatar
      v-for="(u, index) in innerUsers"
      :key="u.id || index"
      :user="u"
      :small="small"
    />
  </div>
</template>
<script lang="ts" setup>
const props = defineProps<{
  users?: { id?: number; avatarUrl?: string | null | undefined }[];
  user?: {
    id?: number;
    avatarUrl?: string | null | undefined;
  };
  small: boolean;
}>();

const innerUsers = computed(() => {
  if (props.user) return [props.user];
  else return props.users?.map((o, i, a) => a[a.length - i - 1]) || [];
});
</script>
<style scoped>
.avatars {
  --offset: var(--grid);
  display: grid;

  grid-auto-rows: 0px;
  padding-bottom: calc(var(--grid) * 2);
  grid-row-gap: var(--offset);

  align-content: end;

  place-content: start;
}

.avatar {
  /* No need for absolute positioning since CSS Grid handles the item placement */
  border: 2px #fff solid;

  box-shadow: none;
}
</style>
